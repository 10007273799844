<template>
  <!-- 申请费用（元） 实际消费(元)   用餐申请（次）   通过审批（次）  -->
  <div class="container_su">
    <div class="handle-box">
      <el-button icon="el-icon-refresh" @click="onRefresh">刷新</el-button>
      <el-button icon="el-icon-download" type="primary" @click="exportExcel"
        >导出</el-button
      >
      <div class="searchCon">
        <!-- <div class="searchItem">
          <span>分局名称</span
          ><el-select
            clearable
            v-model="searchQuery.departmentId"
            placeholder="按分局查询"
            @change="fetchData"
          >
            <el-option
              v-for="item in departmentList"
              :key="item.value"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="searchItem">
          <span>分局名称</span
          ><el-select
            clearable
            v-model="searchQuery.departmentId"
            placeholder="按部门查询"
            @change="fetchData"
          >
            <el-option
              v-for="item in departmentList"
              :key="item.value"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div> -->

        <div class="searchItem">
          <el-input
            v-model="searchQuery.applicantName"
            placeholder="按姓名查询"
          ></el-input>
        </div>
        <div class="searchItem">
          <span>部门名称</span>
          <el-cascader
            :model="searchQuery.departmentId"
            filterable
            :options="departmentTreeList"
            :props="{ value: 'id', label: 'name', checkStrictly: true }"
            clearable
            @change="getDepartId"
            placeholder="按部门查询"
          ></el-cascader>
        </div>
        <div class="searchItem">
          <span>日期选择</span>
          <el-date-picker
            style="width: 380px"
            v-model="daterange"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="getRangDate"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </div>
        <el-button icon="el-icon-search" type="primary" @click="fetchData"
          >查询</el-button
        >
        <el-button @click="setSearchQuery">重置</el-button>
      </div>
    </div>
    <!-- <div>
      <el-row :gutter="10">
        <el-col :span="6">
          <div class="bg-white p-15 radius-4">
            <div class="item">
              <div class="top p-b-10 m-b-10">
                <div class="item-title font-14 m-b-10 color-info">
                  申请费用（元）
                </div>
                <div class="item-content font-20">123.3</div>
              </div>
              <div class="bottom flex justify-between">
                <div class="font-14 color-info">周同比 <span>1%</span></div>
                <div class="font-14 color-info">
                  日环比
                  <span class="red"><i class="el-icon-top"></i>23.3%</span>
                </div>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="bg-white p-15 radius-4">
            <div class="item">
              <div class="top p-b-10 m-b-10">
                <div class="item-title font-14 m-b-10 color-info">
                  实际消费(元)
                </div>
                <div class="item-content font-20">322.5</div>
              </div>
              <div class="bottom flex justify-between">
                <div class="font-14 color-info">周同比 <span>-</span></div>
                <div class="font-14 color-info">
                  日环比
                  <span class="green"><i class="el-icon-bottom"></i>13.5%</span>
                </div>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="bg-white p-15 radius-4">
            <div class="item">
              <div class="top p-b-10 m-b-10">
                <div class="item-title font-14 m-b-10 color-info">
                  用餐申请（次）
                </div>
                <div class="item-content font-20">12</div>
              </div>
              <div class="bottom flex justify-between">
                <div class="font-14 color-info">
                  周同比
                  <span class="green"><i class="el-icon-bottom"></i>2.5%</span>
                </div>

                <div class="font-14 color-info">
                  日环比
                  <span class="red"><i class="el-icon-top"></i>1.5%</span>
                </div>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="bg-white p-15 radius-4">
            <div class="item">
              <div class="top p-b-10 m-b-10">
                <div class="item-title font-14 m-b-10 color-info">
                  通过审批（次）
                </div>
                <div class="item-content font-20">12</div>
              </div>
              <div class="bottom flex justify-between">
                <div class="font-14 color-info">
                  周同比
                  <span class="red"><i class="el-icon-top"></i>2.5%</span>
                </div>

                <div class="font-14 color-info">
                  日环比
                  <span class="red"><i class="el-icon-top"></i>1.5%</span>
                </div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div> -->
    <el-table
      :data="tableData"
      border
      stripe
      style="width: 100%"
      max-height="700"
      v-loading="loading"
    >
      <template v-for="v in propList">
        <el-table-column
          v-if="v.type"
          :key="v.prop"
          :label="v.label"
          :width="v.width"
        >
          <template slot-scope="scope">
            <div v-if="v.type == 'status'">
              <span :style="{ color: statusList[scope.row.status].color }">{{
                statusList[scope.row.status].text
              }}</span>
            </div>

            <div v-if="v.type == 'type'">
              <span :style="{ color: typeList[scope.row.mealType].color }">{{
                typeList[scope.row.mealType].text
              }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          v-else
          :key="v.id"
          :align="v.align"
          :prop="v.prop"
          :label="v.label"
          :width="v.width"
        >
        </el-table-column>
      </template>
    </el-table>
    <div class="pagination">
      <el-pagination
        :current-page="pageInfo.startPage"
        :page-sizes="[10, 20, 30, 50, 100, 200]"
        :page-size="pageInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageInfo.total"
        @size-change="onSizeChange"
        @current-change="onPageChange"
      />
    </div>
  </div>
</template>
<script>
import api from "@/api/common";
import { download } from "@/utils/request";

export default {
  components: {},
  data() {
    return {
      departmentTreeList: [],
      tableData: [],
      departmentList: [],
      emptyObj: {},
      searchQuery: {
        departmentId: "",
        applicantName: "",
        startDate: "",
        endDate: "",
      },
      daterange: [],
      pageInfo: {
        startPage: 1,
        pageSize: 20,
        total: 0,
        apiName: "/meituanApplication",
        userId:sessionStorage.getItem('userId')
      },
      loading: false,
      propList: [
        {
          label: "部门",
          prop: "departmentName",
          align: "center",
          width: "",
          type: "",
        },
        {
          label: "申请人",
          prop: "applicantName",
          align: "center",
          width: "",
          type: "",
        },
        {
          label: "点餐类型",
          prop: "mealType",
          align: "center",
          width: "",
          type: "type",
        },
        {
          label: "申请金额",
          prop: "amount",
          align: "center",
          width: "",
          type: "",
        },
        {
          label: "实际消费",
          prop: "usedAmount",
          align: "center",
          width: "",
          type: "",
        },
        {
          label: "审批状态",
          prop: "status",
          align: "center",
          width: "",
          type: "status",
        },
        {
          label: "审批人",
          prop: "approverName",
          align: "center",
          width: "",
          type: "",
        },
        {
          label: "申请时间",
          prop: "createTime",
          align: "center",
          width: "160",
          type: "",
        },
        {
          label: "审批时间",
          prop: "approverTime",
          align: "center",
          width: "160",
          type: "",
        },
      ],
      statusList: [
        //	0-未审批，1-审批通过 ，2 审批拒绝，3 取消申请
        {
          text: "未审批",
          color: "#3c9cff",
        },
        {
          text: "审批通过",
          color: "#5ac725",
        },
        {
          text: "审批拒绝",
          color: "#f56c6c",
        },
        {
          text: "取消申请",
          color: "#f9ae3d",
        },
        {
          text: "通过初审",
          color: "#f9ae3d",
        },
      ],
      typeList: [
        {
          text: "误餐点餐",
          color: "#3c9cff",
        },
        {
          text: "招待用餐",
          color: "#5ac725",
        },
      ],
    };
  },
  created() {
    this.fetchData();
    this.getTreeList();
  },
  mounted() {
    //将初始搜索条件保存
    this.emptyObj = JSON.parse(JSON.stringify(this.searchQuery));
  },
  methods: {
    setSearchQuery() {
      this.searchQuery = JSON.parse(JSON.stringify(this.emptyObj));
      this.fetchData();
    },

    //导出不能传空字段
    async exportExcel() {
      let obj = {};

      for (const key in this.searchQuery) {
        if (this.searchQuery[key]) {
          obj[key] = this.searchQuery[key];
        }
      }

      let res = await download({
        url: "/meituanDepartmentApprover/exportExcel",
        params: obj,
      });
    },
    getDepartId(e) {
      this.searchQuery.departmentId = e[e.length - 1];
    },
    onPageChange(page) {
      this.pageInfo.startPage = page;
    },
    onSizeChange(size) {
      this.pageInfo.pageSize = size;
    },
    onRefresh() {
      // this.fetchData();
    },
    async getTreeList() {



      let res = await api.commonPost({
        apiName: "/department/getTreeList",
      });
      this.departmentTreeList = res.data;
      console.log("res", res);
    },
    getRangDate(e) {
      this.searchQuery.startDate = e[0];
      this.searchQuery.endDate = e[1];
    },
    async getDepartList() {
      let res = await api.commonPost({ apiName: "/department/getBranchList" });
      this.departmentList = res.data;
    },
    async fetchData() {
      this.loading = true;
      try {
        let searchObj = {};

        for (const key in this.searchQuery) {
          if (this.searchQuery[key]) {
            searchObj[key] = this.searchQuery[key];
          }
        }
        let obj = Object.assign({}, this.pageInfo, searchObj);
        let res = await api.commonGet(obj);
        if (res.code == 200) {
          this.tableData = res.data.data;
          this.pageInfo.total = res.data.total;
        }
      } catch (error) {
        console.log("error", error);
      }
      this.loading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.handle-box {
  margin-bottom: 20px;
}
.del-dialog-cnt {
  font-size: 16px;
  text-align: center;
}
.searchCon {
  display: inline-block;
}
.searchItem:nth-child(1) {
  margin-left: 20px;
}
.searchItem {
  display: inline-block;
  margin-right: 20px;
}
.searchItem span {
  padding: 0 20px;
  background: #f5f7fa;
  color: #909399;
  line-height: 40px;
  height: 40px;
  display: none;
  border: 1px solid #dcdfe6;
  border-right: none;
  border-radius: 4px;
}
.color-info {
  color: #999;
}
</style>
